// app.vue
<template>
    <div class="home-app">
        <div class="home-nav">
            <div class="home-nav-content">
                <div class="nav-logo">
                    <img src="./static/icon.png"> 
                    <div class="name">视频大爆炸</div>
                </div>
                <div 
                    class="btn"
                    @mouseenter="onShowCode"
                    @mouseout="onHideCode">
                    获取卡密，马上使用
                </div>
                <div 
                    v-if="codeShow"
                    @mouseenter="onShowCode"
                    @mouseout="onHideCode"
                    class="contact-dialog animate__animated animate__zoomInDown">
                    <img src="./static/code.jpg">
                </div>
            </div>
        </div>
        <div class="home-content">
            <div class="text">
                <div class="title">普通电脑也能用的<div class="focus">AI视频处理</div>工具</div>
                <!-- <div class="des">本地AI模型赋能，数据更安全。一键批量作图，解放双手</div> -->
            </div>
            <div id="detail">
                <!-- <div class="detail-item">
                    <img src="/static/done.svg" />
                    <div>
                        视频字幕识别提取，生成字幕剧本、字幕文件、文案
                    </div>
                </div>
                <div class="detail-item">
                    <img src="/static/done.svg" />
                    <div>
                        提取特定人物的镜头，智能模型加持，解放双手
                    </div>
                </div>
                <div class="detail-item">
                    <img src="/static/done.svg" />
                    <div>
                        镜头内容检测分类，随心所欲提取目标镜头
                    </div>
                </div>
                <div class="detail-item">
                    <img src="/static/done.svg" />
                    <div>
                        多种字幕消除算法，满足不同场景需求
                    </div>
                </div>
                <div class="detail-item">
                    <img src="/static/done.svg" />
                    <div>
                        自定义分镜，镜头分割敏感度随意调整
                    </div>
                </div> -->
            </div>
            <div class="download">
                <div
                    @click="onPanLink" 
                    class="pan-link">下载速度太慢？可往百度网盘下载</div>
                <div 
                    @click="onDownloadVersion"
                    class="download-btn">
                    <img src="./static/windows-fill.svg"/>
                    <span>立即下载 <span style="font-size: 12px; color: #00c1cd;">(解压即用，免安装)</span> </span>
                </div>
                <div class="download-des">支持Win 10及以上的64位系统</div>
                <div style="margin-top: 24px; font-size: 16px; color: #00c1cd;">注意：解压路径请不要包含中文和特殊字符，否则部分功能可能无法正常使用。</div>
            </div>
        </div>
        <div class="banner-bg">
            <img src="./static/bg1.png">
            <img src="./static/bg2.png">
            <img src="./static/bg3.png">
        </div>
        <div class="home-mask"></div>
    </div>
</template>

<script>
import Typed from 'typed.js'
import axios from 'axios'

export default {
    data() {
        return {
            codeShow: false,
        }
    },
    methods: {
        onPanLink() {
            window.open('https://pan.baidu.com/s/1ycYHNee2k3p66wtLJyhauw?pwd=6p8i', '_blank')
        },
        onShowCode() {
            this.codeShow = true
        },
        onHideCode() {
            this.codeShow = false
        },
        async getLatest() {
            // let result = await axios.post(`https://inspirecreate.ai/latestVersion`, {
            //     appName: 'inspirecreate.ai'
            // })

            // let versionInfo = result.data.data

            // let exeName = versionInfo.exeName

            let exeName = 'inspirecreate.zip'
            let url = `https://inspirecreate.ai/version/${exeName}`
            
            return {
                exeName, 
                url
            }
        },
        async onDownloadVersion() {

            let {
                exeName,
                url
            } = await this.getLatest()

            const link = document.createElement('a')
            link.href = url
            link.download = exeName // 提供下载后的文件名
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    },
    mounted() {
        const typed = new Typed('#detail', {
            strings: ['视频字幕识别、提取人物场景、提取物体场景、字幕消除、智能分镜'],
            typeSpeed: 100,
            loop: true
        })

        let target = 0

        let setDisplay = () => {
            let imgs = document.querySelectorAll('.banner-bg img')
            
            for (let i = 0; i < imgs.length; i++) {
                let item = imgs[i]
                if (i == target) {
                    item.setAttribute('style', '')
                } else {
                    item.setAttribute('style', 'display: none;')
                }
            }

            target = (target + 1) % 3
        }
        setDisplay()
        setInterval(setDisplay, 1500)
    }
}
</script>

<style lang="scss">
.home-app {
    position: relative;
    height: 100%;
    background: #000;
    font-family: PingFang SC, sans-serif;
    user-select: none;
    min-width: 580px;
    background-image: url('/static/bg.gif') !important;
    background-repeat: repeat !important;

    .home-content {
        position: absolute;
        padding: 48px 48px 64px;
        top: 56px;
        left: 0;
        display: flex;
        height: calc(100% - 56px);
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 99;
        overflow-y: auto;

        &::-webkit-scrollbar {
            position: absolute;
            right: -12px;
            width: 10px;
            height: 10px;
        }

        /* 滚动条上的滚动滑块，设置背景色和圆角 */
        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, .2);
            border-radius: 6px;
        }

        /* 滚动条上的滑轨属性，设置背景色和其他属性 */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, .04);
            border-radius: 6px;
        }

        #detail {
            display: flex;
            flex-direction: column;
            padding: 32px;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 24px;
            max-width: 640px;
            line-height: 48px;
            text-align: center;

            .detail-item {
                display: flex;
                align-items: flex-start;

                & + .detail-item {
                    margin-top: 24px;
                }

                img {
                    width: 22px;
                    margin-top: 8px;
                    margin-right: 12px;
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;

            .des {
                text-align: center;
                margin-top: 24px;
                font-size: 22px;
            }

            .title {
                display: flex;
                align-items: center;
                font-size: 36px;
                font-weight: 700;
                white-space: nowrap;
                color: #fff;

                .focus {
                    margin: 0 12px;
                    font-size: 48px;
                    color: #fff;
                }
            }
        }

        .download {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .pan-link {
                font-size: 12px;
                color: #ffcaa8;
                margin-bottom: 12px;
                cursor: pointer;
                text-decoration: underline;
            }

            &-des {
                margin-top: 12px;
                font-size: 12px;
                color: #fff;
            }

            &-btn {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 264px;
                height: 54px;
                border-radius: 27px;
                background: #fff;
                font-weight: 700;
                font-size: 18px;
                cursor: pointer;
                overflow: hidden;
                user-select: none;

                @keyframes light-line {
                    0% {
                        transform: translateX(-100%) rotate(-45deg);
                    }
                    100% {
                        transform: translateX(100%) rotate(-45deg);
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, #fff, #fceef0 40.1%, #fceef0 55.21%, #f0f3fe);
                    opacity: 0.5;
                    animation: light-line 1.6s linear infinite;
                }

                span {
                    margin-top: -2px;
                }

                img {
                    margin-right: 12px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .home-nav {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 56px;
        z-index: 199;
        padding: 0 36px;

        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 0 auto;
            height: 100%;
            max-width: 902px;
            z-index: 199;

            .contact-dialog {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 76px;
                right: 0;
                background: #fff;
                width: 200px;
                border-radius: 12px;
                z-index: 199;
                overflow: hidden;
                height: 204px;
                pointer-events: none;

                img {
                    width: 100%;
                }
            }

            .nav-logo {
                display: flex;
                align-items: center;
                color: #00c1cd;
                font-weight: 700;
                font-size: 20px;

                .name {
                    margin-left: 10px;
                    letter-spacing: 2px;
                }
            }

            .btn {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 18px;
                font-size: 14px;
                box-sizing: border-box;
                background: linear-gradient(270deg, #ffcaa8, #ffddc7);
                border-radius: 7px;
                cursor: pointer;
                overflow: hidden;
                user-select: none;

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .15) 40.1%, hsla(0, 0%, 100%, .15) 55.21%, hsla(0, 0%, 100%, 0));
                    animation: light-line 1.6s linear infinite;
                }

                @keyframes light-line {
                    0% {
                        transform: translateX(-100%) rotate(-45deg);
                    }
                    100% {
                        transform: translateX(100%) rotate(-45deg);
                    }
                }
            }
        }

        img {
            width: 36px;
        }
    }

    .banner-bg {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 0;
        opacity: 0.3;

        img {
            margin-top: 48px;
            max-width: 70%;
            max-height: 80%;
        }
    }

    .home-mask {
        position: relative;
        height: 100%;
        background: linear-gradient(180deg,rgba(35,35,35,0.4) 32%,rgba(35,35,35,.75) 62.6%,#232323);
        z-index: 1;
    }
    
}
</style>

<style lang="scss">
    html, body, div, h1, h2, h3, h4, h5 {
        margin: 0;
        box-sizing: border-box;
    }
    
    html, body {
        width: 100%;
        height: 100%;
        font-family: "Google Sans",arial,sans-serif;
        overflow: hidden;
    }
    
    :root {
        --animate-duration: 1000ms;
        --animate-delay: 0.9s;
    }
</style>