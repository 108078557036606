<template>
    <div>hello home</div>
</template>

<script>
    export default {
        name: 'HOME',
        data() {
            return {

            }
        },
        methods: {
            
        }
    }
</script>