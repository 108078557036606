import Vue from 'vue'
import Router from 'vue-router'

import home from './home.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
        path: '/',
        name: 'HOME',
        component: home
    },
    {
        path: '/home',
        name: 'HOME',
        component: home
    },
  ]
})